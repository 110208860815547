<template>
  <div class="row">
    <q-space />
    <q-badge
      rounded
      :color="
        getCurrentStep().content.length
          ? getBadgePaticipantColor(getCurrentStep().content[0].statuses.status)
          : 'grey-7'
      "
      label="Ver Feedback"
      style="cursor: pointer"
      class="text-uppercase card-badge"
      v-if="
        getCurrentStep().content.length
          ? getCurrentStep().content[0].statuses.status ==
            'Content_to_be_improved'
          : false
      "
      @click="showModal('show')"
    />
  </div>

  <q-item-label
    lines="1"
    :class="{
      'q-mt-md':
        getCurrentStep().content.length &&
        getCurrentStep().content[0].statuses.status != 'Content_to_be_improved',
    }"
    class="card-title q-mb-sm text-weight-bolder text-uppercase text-justify"
    >{{ data.mission.title }}</q-item-label
  >
  <div class="card-data">
    <q-linear-progress size="30px" :value="progress / 100" color="green">
      <div class="absolute-full flex flex-center">
        <q-badge color="white" text-color="black" :label="progressLabel" />
      </div>
    </q-linear-progress>
  </div>

  <q-card-actions class="row q-mt-md q-pa-none">
    <q-badge
      rounded
      color="red-7"
      class="text-uppercase q-pa-sm"
      :label="
        showDeadlineBadge(
          data.partner_start_current_step,
          getCurrentStep(),
          data.mission.end_at
        )
      "
      v-if="
        getCurrentStep().when_date ||
        getCurrentStep().when_integer ||
        data.mission.end_at
      "
    />
    <q-space />
    <template v-for="(action, i) in actionsConfig()" :key="i">
      <q-btn
        flat
        :icon="action.icon"
        :color="action.color"
        @click="action.action"
        :loading="action.loading"
        v-if="action.condition"
      >
        <q-tooltip class="bg-black">{{ $t(action.tooltip) }}</q-tooltip>
      </q-btn>
    </template>
  </q-card-actions>

  <show-steps-component
    v-model="stepsModal"
    v-if="stepsModal"
    :progress="progress / 100"
    :data="data.mission.steps"
    :progressLabel="progressLabel"
    :current="data.current_mission_steps"
    :start_current_step="data.partner_start_current_step"
    @sendContent="closeModalAndSendContent()"
  />
  <exit-mission-component v-model="exitMission" :data="data" />
  <upload-component
    v-if="uploadLinks"
    v-model="uploadLinks"
    :step="getCurrentStep()"
    @close="closeModal()"
    :start_current_step="data.partner_start_current_step"
  />
</template>

<script setup>
import {
  showDeadlineBadge,
  getBadgePaticipantColor,
} from "../helpers/script_helpers";
import ExitMissionComponent from "./internal-components/ExitMissionComponent.vue";
import UploadComponent from "./internal-components/UploadComponent.vue";
import { createNamespacedHelpers, useGetters } from "vuex-composition-helpers";
import ShowStepsComponent from "./ShowStepsComponent.vue";
import { useI18n } from "vue-i18n";

import { computed, onMounted, ref, defineProps, defineEmits } from "vue";

const missionsNamespace = createNamespacedHelpers("missions");

const emit = defineEmits(["reloadFn"]);

const props = defineProps({
  data: {
    type: Object,
    required: true,
  },
});

const { t } = useI18n();
// eslint-disable-next-line vue/no-setup-props-destructure
const {
  status,
  mission,
  total_mission_steps,
  current_mission_steps,
  total_partner_contents,
} = props.data;

const { fetchMissions, fetchDownloadSignedTerm } = missionsNamespace.useActions(
  ["fetchMissions", "fetchDownloadSignedTerm"]
);
const { getDownloadMissionTermConf } = missionsNamespace.useGetters([
  "getDownloadMissionTermConf",
]);
const { partnerId } = useGetters({
  partnerId: "home/getPartnerId",
});

const stepsModal = ref(false),
  exitMission = ref(false),
  uploadLinks = ref(false);

const progress = computed(() =>
  total_partner_contents == 0
    ? 0
    : (total_partner_contents / total_mission_steps) * 100
);

const progressLabel = computed(() => progress.value.toFixed(0) + "%");

const showModal = (type) => {
  switch (type) {
    case "show":
      stepsModal.value = true;
      break;
    case "exit":
      exitMission.value = true;
      break;
    case "upload":
      uploadLinks.value = true;
      break;
  }
};

const getCurrentStep = () =>
  current_mission_steps
    ? mission.steps.find((e) => e.id == current_mission_steps)
    : mission.steps[0];

const isContentReceivedOrApproved = (currentStep) =>
  currentStep.content[0].status === "Content_received" ||
  currentStep.content[0].status === "Content_approved";

const uploadBtn = () => {
  const currentStep = getCurrentStep();

  if (status.status !== "approved") return false;
  if (!Object.keys(currentStep).length) return true;
  if (isExpired(currentStep.when_date)) return false;

  return (
    !currentStep.content.length || !isContentReceivedOrApproved(currentStep)
  );
};

function isExpired(inputDate) {
  // Verifica se a data de entrada é null ou undefined
  if (!inputDate) return false;

  const inputDateTime = new Date(inputDate);

  if (isNaN(inputDateTime)) return false;

  const currentDateTime = new Date();
  return inputDateTime < currentDateTime;
}

const download_signed_term = ref(false);

const actionsConfig = () => [
  {
    icon: "sim_card_download",
    color: "gray",
    tooltip: t("missions.progress_actions.download_signed_term"),
    action: () => {
      download_signed_term.value = true;
      return fetchDownloadSignedTerm({
        data: {
          payload: { mission_id: props.data.mission.id },
          partner_id: partnerId.value,
        },
        conf: getDownloadMissionTermConf.value,
      }).finally(() => (download_signed_term.value = false));
    },
    condition: true,
    loading: download_signed_term.value,
  },
  {
    icon: "menu_open",
    color: "black",
    tooltip: t("missions.progress_actions.progress_tooltip"),
    action: () => showModal("show"),
    condition: status.status !== "analyzing" && status.status !== "disapproved",
    loading: false,
  },
  {
    icon: "upload",
    color: "primary",
    tooltip: t("missions.progress_actions.content_tooltip"),
    action: () => showModal("upload"),
    condition: uploadBtn(),
    loading: false,
  },
  {
    icon: "exit_to_app",
    color: "red",
    tooltip: t("missions.progress_actions.exit_tooltip"),
    action: () => showModal("exit"),
    condition: status.status !== "analyzing" && status.status !== "disapproved",
    loading: false,
  },
];

const closeModalAndSendContent = () => {
  stepsModal.value = false;
  uploadLinks.value = true;
};

const closeModal = async () => {
  emit("reloadFn", false);
  uploadLinks.value = false;
  await fetchMissions().then(() => emit("reloadFn", true));
};

onMounted(() => {
  const isShowFeedback = localStorage.getItem("showFeedBack");
  if (
    isShowFeedback &&
    getCurrentStep().content.length &&
    getCurrentStep().content[0].statuses.status == "Content_to_be_improved"
  ) {
    const { slug } = JSON.parse(isShowFeedback);
    if (slug == mission.slug) showModal("show");
    localStorage.removeItem("showFeedBack");
  }
});
</script>

<style scoped lang="scss">
.card-badge {
  top: 0;
  right: 1px;
  padding: 15px;
  transform: translateY(-50%);
}
.card-badge:hover {
  opacity: 0.8;
}

.card-title {
  font-size: 12pt;
}
.card-targets {
  width: 30px;
  height: 30px;
}
.card-data {
  font-size: 13pt;
}

@media screen and (max-width: 1600px) {
  .card-title {
    font-size: 12pt;
  }
  .card-targets {
    width: 25px;
    height: 25px;
  }
  .card-hashtags {
    font-size: 9pt;
  }
  .card-data {
    font-size: 10pt;
  }
}

@media screen and (max-width: 1368px) {
  .card-title {
    font-size: 10pt;
  }
  .card-targets {
    width: 25px;
    height: 25px;
  }
  .card-hashtags {
    font-size: 8pt;
  }
  .card-data {
    font-size: 9pt;
  }
}

.mobile {
  .card-title {
    font-size: 10pt;
  }
  .card-data {
    font-size: 11pt;
  }
}
</style>
